import Vue from "vue";
import SmallBlogPostCard from "../public/general/SmallBlogPostCard.vue";
export default Vue.extend({
    components: {
        SmallBlogPostCard: SmallBlogPostCard,
    },
    props: {
        posts: {
            type: Array,
            required: true,
        },
    },
});
