var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import SmallBlogPostCard from "../general/SmallBlogPostCard.vue";
import SmallBlogPostsSection from "../../ui/SmallBlogPostsSection.vue";
export default Vue.extend({
    components: {
        SmallBlogPostsSection: SmallBlogPostsSection,
    },
    apollo: {
        healthCoverProviders: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query HealthCoverProvidersQuery {\n          healthCoverProviders {\n            id\n            name\n            quoteImageUrl\n          }\n        }\n      "], ["\n        query HealthCoverProvidersQuery {\n          healthCoverProviders {\n            id\n            name\n            quoteImageUrl\n          }\n        }\n      "]))),
            fetchPolicy: "cache-first",
        },
        liveBlogPostsByTag: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query ListingBlogPostsQuery($tag: String!) {\n          liveBlogPostsByTag(tag: $tag, amount: 4) {\n            id\n            ...SmallBlogPostCard_post\n          }\n        }\n        ", "\n      "], ["\n        query ListingBlogPostsQuery($tag: String!) {\n          liveBlogPostsByTag(tag: $tag, amount: 4) {\n            id\n            ...SmallBlogPostCard_post\n          }\n        }\n        ", "\n      "])), SmallBlogPostCard.options.fragments.post),
            variables: { tag: "oshc" },
        },
        contentPage: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      {\n        contentPage(key: \"health-cover\") {\n          originalBannerImageUrl\n        }\n      }\n    "], ["\n      {\n        contentPage(key: \"health-cover\") {\n          originalBannerImageUrl\n        }\n      }\n    "]))),
    },
});
var templateObject_1, templateObject_2, templateObject_3;
