var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"banner-landing"},[_c('div',{staticClass:"container banner-grid"},[_c('content-page-banner',{attrs:{"page-key":"health-cover"}},[_c('router-link',{staticClass:"btn btn-cta btn-orange",attrs:{"to":"/health-cover/details"}},[_vm._v("\n          "+_vm._s(_vm.$t("Get Quote"))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"banner-col-2 flex flex-jc flex-ac"},[_c('div',{staticClass:"banner-promo"},[(_vm.contentPage)?_c('img',{staticClass:"img-resp",attrs:{"src":_vm.contentPage.originalBannerImageUrl,"alt":"Student"}}):_vm._e()])])],1),_vm._v(" "),_c('div',{staticClass:"oval-lg"},[_c('img',{staticClass:"img-resp",attrs:{"src":require('../../../images/oval-lg.svg'),"alt":"background image","aria-hidden":"true","role":"presentation"}})]),_vm._v(" "),_c('div',{staticClass:"oval"},[_c('img',{staticClass:"img-resp",attrs:{"src":require('../../../images/oval.svg'),"alt":"background image","aria-hidden":"true","role":"presentation"}})])]),_vm._v(" "),_c('section',{staticClass:"below-banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"oshc-partner-logo-row"},_vm._l((_vm.healthCoverProviders),function(provider){return _c('div',{key:provider.id,staticClass:"partner-logo"},[_c('img',{staticClass:"img-resp",attrs:{"src":provider.quoteImageUrl,"alt":provider.name}})])}),0),_vm._v(" "),_c('h2',{staticClass:"text-center"},[_vm._v("\n        "+_vm._s(_vm.$t("Partnering with three of Australia's approved OSHC providers"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"flex flex-jc mb-1"},[_c('div',{staticClass:"w-80 text-center"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                "Choose and compare three of Australia's approved OSHC providers and be assured that they will provide the optimal cover to best suit your needs during your stay in Australia. These providers will fulfil your international student visa requirements, and you can simply select a cover based on your budget, or type of policy cover you prefer.",
              ))+"\n          ")])])]),_vm._v(" "),_c('div',{staticClass:"features-wrapper supplier-wrapper"},[_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/24-hour.svg'),"alt":"24 hour support icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("24/7 Support Line"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Each provider offers a level of medical support and assistance 24/7, with support provided in different languages if required.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/on-campus.svg'),"alt":"on campus icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Meet your visa requirements"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Each provider has tailored specific policies designed to meet the requirements for Subclass 500 Student Visa",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/health-icon.svg'),"alt":"health icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Health cover on the go"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Manage your insurance cover, personal details and claims through easy-to-use apps and online portals",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"supp-icon"},[_c('img',{attrs:{"src":require('../../../images/support.svg'),"alt":"support icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h4',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Your language"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Each provider can provide assistance and support in your language if required",
                ))+"\n            ")])])])])])]),_vm._v(" "),_c('section',{staticClass:"blue-line blue-bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"section-heading"},[_c('h2',{staticClass:"text-center mb-0"},[_vm._v("\n          "+_vm._s(_vm.$t("How it works"))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-center"},[_vm._v("\n          "+_vm._s(_vm.$t("4 simple steps to securing your overseas health cover."))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"features-wrapper how-wrapper"},[_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/h1.svg'),"alt":"accommodation icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Get a quote"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Enter your start/arrival date & end dates for a quote. OSHC must cover the full period of your Visa.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/h2.svg'),"alt":"enquiry icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Add to cart"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Add the insurance to the cart and read the PDS for more information on your cover or contact us.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/h3.svg'),"alt":"Customer service icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Checkout"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "Checkout as a guest or sign up to complete the checkout. Please provide all required information.",
                ))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"feature"},[_c('div',{staticClass:"feature-icon"},[_c('img',{attrs:{"src":require('../../../images/a4.svg'),"alt":"Payment icon"}})]),_vm._v(" "),_c('div',{staticClass:"feature-text"},[_c('h5',{staticClass:"mb-0"},[_vm._v("\n              "+_vm._s(_vm.$t("Pay & you're covered!"))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  "After completing payment, your certificate of insurance will be emailed to you, and you're covered!",
                ))+"\n            ")])])])])])]),_vm._v(" "),_c('section',{staticClass:"blue-line"},[_c('div',{staticClass:"container flex flex-jc flex-ac"},[_c('router-link',{staticClass:"btn btn-cta btn-orange",attrs:{"to":"/health-cover/details"}},[_vm._v("\n        "+_vm._s(_vm.$t("Get Quote"))+"\n      ")])],1)]),_vm._v(" "),(_vm.liveBlogPostsByTag && _vm.liveBlogPostsByTag.length)?_c('small-blog-posts-section',{attrs:{"posts":_vm.liveBlogPostsByTag}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }