var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
export default Vue.extend({
    fragments: {
        post: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment SmallBlogPostCard_post on BlogPost {\n        slug\n        title\n        thumbnail\n      }\n    "], ["\n      fragment SmallBlogPostCard_post on BlogPost {\n        slug\n        title\n        thumbnail\n      }\n    "]))),
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    computed: {
        truncatedTitle: function () {
            var titleSize = 55;
            var title = this.post.title;
            if (title.length > titleSize) {
                return title.substr(0, titleSize) + "...";
            }
            return title;
        },
    },
});
var templateObject_1;
